import { useState, useEffect } from 'react'
import {
    toInteger,
    flow,
    sortBy,
    get,
    reverse,
    includes,
    filter,
    compact,
    uniq,
    without,
    toLower,
} from 'lodash'
import {
    ListSortDirection,
} from '@docpace/shared-ts-types/utils/webutilities'
import {
    makeAppointmentStatusCountToday,
} from '@docpace/shared-ts-types/base/Provider'

import { AdminProviderDetailFragment } from '@docpace/shared-graphql/fragments'
import { ProviderDetailFragment, ProviderOverviewFragment } from '@docpace/shared-graphql/fragments'
import { ProviderDepartmentOptionSort } from './useSortProviderDepartmentOptions'
import { ManagerDetailFragment } from '@docpace/shared-graphql/fragments'

export interface ExtendedProvider extends ProviderDetailFragment {
    isFavorited: boolean
    isSelected: boolean
    appointmentCountToday: number
}

export class ProviderSort extends ProviderDepartmentOptionSort {
    static readonly DISPLAY_NAME = new ProviderSort(
        'Display Name', 
        'displayName',
        ListSortDirection.ASC,
        [toLower]
    )
    static readonly FIRST_NAME = new ProviderSort(
        'First Name', 
        'firstName',
        ListSortDirection.ASC,
        [toLower]
    )
    static readonly LAST_NAME = new ProviderSort(
        'Last Name', 
        'lastName',
        ListSortDirection.ASC,
        [toLower]
    )
    static readonly PROVIDER_ID = new ProviderSort(
        'Provider Id', 
        'providerId',
        ListSortDirection.ASC,
        [toLower]
    )
    static readonly APPOINTMENT_COUNT = new ProviderSort(
        'Appointment Count',
        'recentAppointmentCountsPerHours.aggregates.sum.count',
        ListSortDirection.DESC,
        [toInteger]
    )
    static readonly DEPARTMENT_COUNT = new ProviderSort(
        'Deptartment Count',
        'recentAppointmentCountsPerHours.aggregates.distinctCount.departmentId',
        ListSortDirection.DESC,
        [toInteger]
    )
    static readonly APPOINTMENT_TYPE_COUNT = new ProviderSort(
        'Appt Type Count',
        'recentAppointmentCountsPerHours.aggregates.distinctCount.appointmentTypeId',
        ListSortDirection.DESC,
        [toInteger]
    )
    static readonly MANAGER_COUNT = new ProviderSort(
        'Manager Count',
        'managerProviderPermissions.aggregates.distinctCount.managerId',
        ListSortDirection.DESC,
        [toInteger]
    )
    static readonly EXAM_ROOM_COUNT = new ProviderSort(
        'Exam Rooms',
        'examRoomCount',
        ListSortDirection.DESC,
        [toInteger]
    )
    static readonly APPOINTMENT_FETCHING_ENABLED = new ProviderSort(
        'Appt Fetching',
        'fetchAppointmentsEnabled'
    )
    static readonly AUTO_TEXTING_ENABLED = new ProviderSort(
        'Auto Texting',
        'autoTextingEnabledAfter'
    )
    static readonly FAVORITED = new ProviderSort(
        'Is Favorited',
        'isFavorited',
        ListSortDirection.DESC
    )
    static readonly APPOINTMENT_COUNT_TODAY = new ProviderSort(
        "Today's Appointment Counts",
        'appointmentCountToday',
        ListSortDirection.DESC
    )
    static readonly TIME_ONBOARDED = new ProviderSort(
        'Time Onboarded',
        'timeOnboarded'
    )

    static readonly EXCLUDE_FROM_APPOINTMENT_TYPE_CALCULATIONS = new ProviderSort(
        'Exclude from Appt Cycle Estimations',
        'excludeFromAppointmentTypeCalculations'
    )

    // private to disallow creating other instances of this type
    private constructor(
        public readonly key: string,
        public readonly sortPath: string,
        public defaultSort: ListSortDirection = ListSortDirection.ASC,
        public flowFuncs?: ((any: any) => any)[]
    ) {
        super(key, sortPath, defaultSort, flowFuncs)
    }

    toString() {
        return this.key
    }
}

export type ProviderSortKey = keyof typeof ProviderSort

interface UseSortProvidersProps {
    initialSortKey?: ProviderSortKey
    initialSortDirection?: ListSortDirection
    providers: (ProviderDetailFragment | AdminProviderDetailFragment)[]
    favoriteProviderIds: string[]
    filteredProviderIds?: string[]
    initialSelectedProviderIds?: string[]
    selectedProviderIds?: string[]
    isAdminSite?: boolean
    manager: ManagerDetailFragment
}

export interface UseSortProvidersOutput {
    sortByKey: ProviderSortKey
    sortDirection: ListSortDirection
    reverseSortDirection: () => void
    setSortDirection: (sortDirection: ListSortDirection) => void
    setSortByKey: (sortByKey: ProviderSortKey) => void
    sortedProviders: ExtendedProvider[]
    favoriteProviderIds: string[]
    filteredProviderIds?: string[]
    selectedProviderIds: string[]
    // setSelectedProviderIds: (providerIds: string[])=>void
    // addSelectProviderId: (providerId: string)=>void
    // deselectProviderId: (providerId: string)=>void
    selectedProviders: ExtendedProvider[]
    unselectedProviders: ExtendedProvider[]
}

export function useSortProviders({
    initialSortKey = 'DISPLAY_NAME',
    initialSortDirection = ListSortDirection.ASC,
    providers,
    favoriteProviderIds = [],
    filteredProviderIds,
    selectedProviderIds,
    isAdminSite,
    manager
}: UseSortProvidersProps): UseSortProvidersOutput {
    providers = providers?.map((p) => {
        const pAny: any = p
        return {
            ...p,
            isFavorited: includes(favoriteProviderIds, p.providerId),
            isSelected: includes(selectedProviderIds, p.providerId),
            appointmentCountToday: makeAppointmentStatusCountToday({
                provider: pAny,
                isAdminSite,
                manager
            }),
        }
    })

    providers =
        filteredProviderIds && filteredProviderIds?.length > 0
            ? filter(providers, ({ providerId }) =>
                  includes(filteredProviderIds, providerId)
              )
            : providers

    const [sortByKey, _setSortByKey] = useState<ProviderSortKey>(initialSortKey)
    const [sortDirection, setSortDirection] =
        useState<ListSortDirection>(initialSortDirection)

    const sortValue = ProviderSort?.[sortByKey]
    const doFlow = flow(sortValue?.flowFuncs ?? [])

    const sortedProviders = flow([
        (r: ExtendedProvider[]) =>
            sortBy(r, (item) => doFlow(get(item, sortValue?.sortPath))),
        sortDirection === ListSortDirection.DESC ? reverse : (r) => r,
    ])(providers)

    const reverseSortDirection = () => {
        setSortDirection(
            sortDirection === ListSortDirection.ASC
                ? ListSortDirection.DESC
                : ListSortDirection.ASC
        )
    }

    const setSortByKey = (newSortByKey: ProviderSortKey) => {
        if (newSortByKey === sortByKey) {
            reverseSortDirection()
        } else {
            _setSortByKey(newSortByKey)
            setSortDirection(
                ProviderSort?.[newSortByKey]?.defaultSort ??
                    ListSortDirection.ASC
            )
        }
    }

    return {
        sortByKey,
        sortDirection,
        setSortDirection,
        setSortByKey,
        reverseSortDirection,
        sortedProviders,
        favoriteProviderIds,
        filteredProviderIds,
        selectedProviderIds: selectedProviderIds ?? [],
        // setSelectedProviderIds,
        // addSelectProviderId: (providerId)=>setSelectedProviderIds(compact(uniq([ providerId, ...selectedProviderIds ])) ),
        // deselectProviderId: (providerId)=>setSelectedProviderIds(compact(uniq(without(selectedProviderIds, providerId)))),
        selectedProviders: filter(sortedProviders, ({ providerId }) =>
            includes(selectedProviderIds, providerId)
        ),
        unselectedProviders: filter(
            sortedProviders,
            ({ providerId }) => !includes(selectedProviderIds, providerId)
        ),
    }
}
